import React, { useState } from "react";
import { Link } from "react-router-dom";

const AboutFeature = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="ab-feature-area p-relative">
        <div
          className="ab-fea-bg"
          style={{ backgroundImage: `url("assets/img/bg/feature-bg.jpg")` }}
        >
          
        </div>
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-lg-4 offset-lg-8 col-md-6 offset-md-6 col-12">
              <div className="tp-feaure-ct-box black-bg">
                <div className="tp-slider-text tp-ct-position d-inline-block">
                  <span className="mb-25 d-inline-block">
                    Transforma tus ideas en impresiones memorables
                  </span>
                  <h3 className="tp-slider-title mb-25">
                    Creatividad y Calidad <br />
                    en Cada Detalle
                  </h3>

                  <Link to="/service" className="tp-slider-btn" tabIndex="-1">
                    <span>
                      {/* SVG remains unchanged */}
                    </span>
                    Conoce Más
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </>
  );
};

export default AboutFeature;
