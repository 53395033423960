import React from "react";
import { Link } from "react-router-dom";

const FullScreenBtn = () => {
  return (
    <>
      {/* <!-- full screen btn area  --> */}
      <div className="tp-theme-btn-area pt-90 pb-140">
        <div className="container">
          <div className="row justify-content-center p-relative">
            <div className="col-lg-12 d-flex justify-content-center">
              <Link to="/contact">
                <div className="tp-theme-btn d-flex align-items-center white-bg">
                  <div className="tp-btn-inner">
                    <p className="mb-0"  style={{ color: '#0EFDF3' }}>Inicia tu Proyecto</p>
                    <b>Contacta con Nosotros</b>
                    <span className="d-inline-block">
                      <svg
                        width="46"
                        height="8"
                        viewBox="0 0 46 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* SVG path remains unchanged */}
                      </svg>
                      <svg
                        width="46"
                        height="8"
                        viewBox="0 0 46 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* SVG path remains unchanged */}
                      </svg>
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            {/* The tp-hr-border div remains unchanged */}
          </div>
        </div>
      </div>
      {/* <!-- full screeen btn are end  --> */}
    </>
  );
};

export default FullScreenBtn;
